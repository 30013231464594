import { IconDashboard, IconUsers, IconSearch, IconReportAnalytics, IconFileReport } from '@tabler/icons';

const icons = {
    IconDashboard,
    IconUsers,
    IconSearch,
    IconReportAnalytics,
    IconFileReport
};

const pages = {
    id: 'pages',
    title: '',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: 'app/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'search',
            title: 'Search Child',
            type: 'item',
            url: 'app/search-child',
            icon: icons.IconSearch,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Children',
            type: 'item',
            url: 'app/childrens',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false,
            children: [
                {
                    id: 'visit-reports',
                    title: 'Visit Report',
                    type: 'item',
                    url: 'app/visit-report',
                    icon: icons.IconFileReport,
                    breadcrumbs: false
                },
                {
                    id: 'customer-reports',
                    title: 'Customer Report',
                    type: 'item',
                    url: 'app/customer-report',
                    icon: icons.IconFileReport,
                    breadcrumbs: false
                },
            ]
        },
    ]
};

export default pages;
