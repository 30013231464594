import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
const AuthLogin = Loadable(lazy(() => import('views/authentication/LoginPanelContainer')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([{ path: '/', element: <AuthLogin /> }, MainRoutes, AuthenticationRoutes], config.basename);
}
