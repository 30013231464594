import logo from 'assets/images/logo.svg';
import PropTypes from 'prop-types';

const Logo = (props) => {
    return <img src={logo} alt="Wonderland-Baku" width={props.width ? props.width : '170'} />;
};

export default Logo;

Logo.propTypes = {
    width: PropTypes.string
};
