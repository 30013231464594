import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Dashboard = Loadable(lazy(() => import('views/dashboard/DashboardContainer')));
const AllCustomers = Loadable(lazy(() => import('views/customers/view/CustomerContainer')));
const CustomerDetail = Loadable(lazy(() => import('views/customers/details/ChildrenDetailContainer')));
const SearchCustomer = Loadable(lazy(() => import('views/customers/search/SearchCustomerContainer')));
const UserProfile = Loadable(lazy(() => import('views/user-profile/UserProfileContainer')));
const VisitReport = Loadable(lazy(() => import('views/customers/reports/visit-report/VisitReportContainer')));
const CustomerReport = Loadable(lazy(() => import('views/customers/reports/customer-report/CustomerReportContainer')));

const MainRoutes = {
    path: '/app',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/childrens',
            element: <AllCustomers />
        },
        {
            path: '/search-child',
            element: <SearchCustomer />
        },
        {
            path: '/child-details/:id',
            element: <CustomerDetail />
        },
        {
            path: '/account-profile',
            element: <UserProfile />
        },
        {
            path: '/visit-report',
            element: <VisitReport />
        }, 
        {
            path: '/customer-report',
            element: <CustomerReport />
        }
    ]
};

export default MainRoutes;
